import React from 'react';
import './Quiz.css';
import Card from '../Card/Card';
import { useState } from 'react';


const Quiz = () => {
    const initialURL = "https://pokeapi.co/api/v2/pokemon";
    const [loading, setLoading] = useState(false);
    const [isHave, setIsHave] = useState(false);
    const [jaPokemonName, setJaPokemonName] = useState("");
    const [pokemon, setPokemon] = useState([]);
    const [visibleAnswer, setVisibleAnswer] = useState(true);

    //1から1025までのランダムな数値を生成する関数
    function onRandomNumber() {
        setLoading(true);
        let randomNumber = Math.floor(Math.random() * 1025 + 1);
        getRandomPokemon(randomNumber);
        setVisibleAnswer(false);
    }

    function onShowAnswer() {
        setVisibleAnswer(true);
    }

    //生成した数値を使ってポケモンのデータを取得する関数
    const getRandomPokemon = async (randomNumber) => {
        //引数に渡した数値をURLに結合してfetchを呼び出す
        let res = await fetch(initialURL + "/" + randomNumber);
        let result = await res.json();
        console.log(result);

        setPokemon(result);
        setLoading(false);
        setIsHave(true);

        //取得したポケモンのデータから、さらに日本語のデータをfetchで取得する
        let jaRes = await fetch(result.species.url);
        let jaResult = await jaRes.json();
        setJaPokemonName(jaResult.names[0].name);
    }





    return (
        <div className='quizBody'>
            {loading ? (
                <h1>ロード中・・・</h1>
            ) : (
                <div className='quizContainer'>
                    {isHave &&
                        <div cardContainer>
                            <Card pokemon={pokemon} jaPokemon={jaPokemonName} visibleAnswer={visibleAnswer} />
                        </div>
                    }
                    <div className="buttonContainer">
                        {isHave &&
                            <button className='showAnswerButton' onClick={onShowAnswer}>答えを表示</button>
                        }
                        {visibleAnswer &&
                            <button className='questionButton' onClick={onRandomNumber}>出題！！</button>
                        }
                    </div>
                </div>
            )}

        </div>
    )
}

export default Quiz