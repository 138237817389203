import React from 'react';
import "./Card.css";

const Card = ({ pokemon, jaPokemon, visibleAnswer }) => {
    return (
        <div className='card'>
            <div className='cardImg'>
                <img src={"https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/" + pokemon.id + ".png"} style={{ width: 200 }} />
            </div>
            {visibleAnswer ? (
                <h3 className='cardName'>{jaPokemon}</h3>
            ) : (
                <h3 className='cardName' style={{ background: "black" }}><img width="48" height="48" src="https://img.icons8.com/fluency/48/pokeball.png" alt="pokeball" /></h3>
            )}
        </div>
    )
}

export default Card