import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="privacy-policy">
                当ページでは、Googleによるアクセス解析ツールを使用しています。これはデータの収集のためにCookieを使用しています。<br />cookieの無効化はお使いのブラウザの設定をご確認ください。この規約に関しての詳細は<a href='https://marketingplatform.google.com/about/analytics/terms/jp/'>Googleアナリティクスサービス利用規約</a>のページや<a href='https://policies.google.com/technologies/ads?hl=ja'>Googleポリシーと規約ページ</a>をご覧ください。
            </div>
            <div className="license">
                <p>Pokémon is copyright © 1998-2024 Pokémon Inc. © 1995-2024 Nintendo/Creatures Inc./GAME FREAK inc. TM, ®
                    and Pokémon character names are trademarks of Nintendo.
                </p>
                <p>
                    <a href="https://icons8.com/icon/eQoYCq7PgMch/pokeball">Pokeball</a>
                    icon by
                    <a href="https://icons8.com">Icons8</a>
                </p>
            </div>
        </footer>
    )
}

export default Footer