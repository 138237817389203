import React from 'react';
import './Navbar.css';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    function handleClick() {
        navigate("/");
    }
    return (
        <nav>
            <div className="title">
                <p>ポケモンクイズ</p>
            </div>

        </nav>
    )
}

export default Navbar