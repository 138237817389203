import Navbar from './components/Navbar/Navbar.js';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Quiz from './components/Quiz/Quiz.js';
import Footer from './components/Footer/Footer.js';


function App() {

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Quiz />}></Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;